




















































































































import {Component, Vue, Watch} from "vue-property-decorator"
import ConfigService from "@/services/ConfigService"
import Config, {ConfigType} from "@/models/Config"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import ConfigModule from "@/store/ConfigModule"
import ConfigResponse from "@/models/responses/ConfigResponse";

@Component
export default class ConfigurationView extends Vue {

  lang: any = getModule(LangModule).lang
  loading: boolean = false

  configs: ConfigResponse = new ConfigResponse()

  created() {
    this.refresh()
  }

  async refresh() {
    await ConfigService.getPublicConfigs(this)
  }

  async patchConfig(config: Config) {
    console.log(config)
    await ConfigService.patchConfig(this, config.key!!, config)
    await this.refresh()
  }
}

